<template>
  <div class="modal-overlay" @click.self="$emit('close')">
    <div class="modal-content">
      <button class="close-btn" @click="$emit('close')">×</button>
      <div class="album-info">
        <img :src="spotifyData?.images[0].url" alt="Album cover">
        <div class="text-and-logo">
          <div class="text-info">
            <p class="artist">{{ album.artist }}</p>
            <p class="title">{{ album.title }}</p>
          </div>
          <div class="spotify-logo">
            <a :href="spotifyData?.external_urls?.spotify" target="_blank">
              <img src="https://upload.wikimedia.org/wikipedia/commons/2/26/Spotify_logo_with_text.svg" alt="Listen on Spotify" />
            </a>
          </div>
        </div>
      </div>


      
      <!-- Tab Navigation -->
      <div class="tab-navigation">
        <button 
          :class="{ active: activeTab === 'spotify' }"
          @click="activeTab = 'spotify'">
          Album Info
        </button>
        <button 
          :class="{ active: activeTab === 'album' }"
          @click="activeTab = 'album'">
          Results
        </button>
      </div>

      <!-- Tab Content for Album Info -->
      <div v-if="activeTab === 'spotify'" class="tab-content">
        <p v-if="loading" class="loading">Loading Album data...</p>
        <p v-if="error" class="error">{{ error }}</p>
        <div v-else>
          <p><strong>Release Date:</strong> {{ spotifyData?.release_date || 'N/A' }}</p>
          <p><strong>Label:</strong> {{ spotifyData?.label || 'N/A' }}</p>
          <div v-if="spotifyData?.tracks?.items">
            <h4>Tracklist: ({{ spotifyData?.total_tracks || 'N/A' }})</h4>
            <ul>
              <li v-for="(track, index) in spotifyData.tracks.items" :key="track.id">
                <strong>{{ index + 1 }}. {{ track.name }}</strong> - {{ formatDuration(track.duration_ms) }}
              </li>
            </ul>
          </div>
        </div>
      </div>

      <!-- Tab Content for Album Info -->
      <div v-if="activeTab === 'album'" class="tab-content">
        <p class="tier">{{ album.tier }} Tier</p>
        <p class="submitted-by">Submitted by: {{ album.submitted_by }}</p>
        <p class="reviewed-at">Reviewed at: {{ album.reviewed_at }}</p>
        <div class="individual-tiers">
          <h3>Individual Tier Ratings:</h3>
          <ul>
            <li v-for="(tier, name) in album.individual_tiers" :key="name">{{ name }}: {{ tier }}</li>
          </ul>
        </div>
      </div>
      
      <router-link :to="'/edit-album/' + album.id" class="edit-link">
        Edit
      </router-link>
    </div>
  </div>
</template>

<script>
import apiService from '../apiService';

export default {
  props: {
    album: Object,
    veto: Boolean,
    veto_by: String
  },
  name: 'albumDetailsModal',
  data() {
    return {
      spotifyData: null,
      loading: true,
      error: null,
      activeTab: 'spotify' // Default to the Spotify Info tab
    };
  },
  async created() {
    if (this.album.spotify_id) {
      try {
        const response = await apiService.getSpotifyAlbum(this.album.spotify_id);
        this.spotifyData = response.data;
      } catch (error) {
        console.error('Error fetching Spotify data:', error);
        this.error = 'Failed to fetch Spotify data';
      } finally {
        this.loading = false;
      }
    } else {
      this.loading = false;
    }
  },
  methods: {
    emitAlbumClicked() {
      this.$emit('albumClicked', this.album);
    },
    formatDuration(durationMs) {
      const minutes = Math.floor(durationMs / 60000);
      const seconds = ((durationMs % 60000) / 1000).toFixed(0);
      return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    }
  }
};
</script>




<style scoped>
/* Existing styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1050;
}

.modal-content {
  position: relative;
  background-color: white;
  color: black;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  font-size: 16px;
  max-height: 90vh;
  overflow-y: auto;
  width: 80%;
  max-width: 700px;
}

.album-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 5px;
}

.album-info img {
  width: 300px; /* Adjust this for your needs */
  height: auto;
}

.text-and-logo {
  display: flex;
  flex-direction: column; /* Default layout: stack text and logo */
  width: 100%;
  padding: 10px 0px;
}

.text-info {
  text-align: center; /* Default: centered text */
}

.text-info p {
  font-size: 24px;
  margin: 5px 0;
}

.spotify-logo {
  margin-top: 5px;
  text-align: center; /* Center the logo under the text by default */
}

.spotify-logo img {
  max-width: 100px; /* Limit the size of the logo */
}

.tab-navigation {
  display: flex;
  justify-content: left;
  position: relative;
}

.tab-navigation button {
  background: #fff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  border-radius: 8px 8px 0 0; /* Rounded top to look like a tab */
  font-weight: bold;
  color: #555; /* Darker text for a cleaner look */
  position: relative;
}

.tab-navigation button.active {
  color: #292929;
  border-bottom: 3px solid #2f32f3;
  top: 0; /* Raise the active tab to appear connected */
  z-index: 1; /* Makes sure active tab stays on top */
}

.tab-navigation button:hover {
  background: #e0e0e0;
}

.tab-content {
  background-color: #f1f1f1; /* Background color to separate it from the tabs */
  border-top: none; /* Removes border between tabs and content */
  border-radius: 0 0 8px 8px; /* Rounded bottom to match the tab's rounded top */
  min-height: 200px; /* Ensures enough space for content */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Adds a subtle shadow */
  padding: 15px;
}

.tab-content p {
    margin: 0px;
  }


.modal-content .artist {
  font-weight: bold;
}

.modal-content .title {
}

.modal-content .tier {
  font-size: 18px;
  font-weight: bold;
}

.veto-info {
  color: red;
  font-weight: bold;
  padding-top: 10px;
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 10; /* Ensures it stays on top */
  background-color: rgba(255, 255, 255, 0.5); /* Semi-transparent background */
  border-radius: 50%;
  padding: 4px 10px;
  font-size: 24px;
}

.close-btn:hover {
  color: #ccc;
}

.modal-content .submitted-by,
.modal-content .reviewed-at,
.modal-content .spotify-url,
.modal-content .individual-tiers {
  margin-top: 5px;
}

.modal-content .individual-tiers h3 {
  margin-bottom: 5px;
}

.modal-content .individual-tiers ul {
  list-style-type: none;
  padding-left: 0;
}

.modal-content .individual-tiers li {
  margin-bottom: 5px;
}

.edit-link {
  display: flex;
  align-items: center;
  padding: 20px;
  text-align: center;
  font-size: 12px;
  color: #333;
}

.edit-link:hover {
  color: #007bff;
}

@media (max-width: 600px) {
  .modal-content {
    width: 100%;
    height: 100%;
    max-width: none;
    max-height: none;
    padding: 0;
    border-radius: 0;
    overflow-y: auto; /* Ensures scrolling if content overflows */
  }

  .album-info {
    flex-direction: column;
    align-items: center;
    padding: 0px;
  }

  .text-and-logo {
    padding: 10px 0px;
    display: flex;
    flex-direction: row; /* On mobile, switch to a row layout */
    justify-content: space-between; /* Align text left and logo right */
    align-items: center;
    width: 100%; /* Ensure full width on mobile */
  }

  .spotify-logo {
    text-align: right; /* Align logo to the right on mobile */
    margin-top: 0;
  }

  .spotify-logo img {
    max-width: 80px; /* Slightly smaller logo on mobile */
  }

  .album-info img {
  width: 100vw; /* Take up full width */
  height: auto; /* Keeps aspect ratio */
  object-fit: cover; /* Ensures image fits nicely */
  margin: 0;
  }


  .text-info {
    text-align: left;
    padding-left: 20px;
    margin-bottom: 0px;
  }
  
  .spotify-logo {
    margin: 0;
    padding-right: 20px;
  }

  .tab-content {
    padding: 20px;
    margin-top: 0px;
  }


}

</style>
