import axios from 'axios';

const apiClient = axios.create({
  baseURL: process.env.VUE_APP_API_URL || 'https://tierlist-backend-cf2959fe8591.herokuapp.com',
  headers: {
    'Content-Type': 'application/json',
    'x-api-key': process.env.VUE_APP_API_KEY // Add your API key here
  }
});

const fetchAlbums = async () => {
  try {
    const response = await apiClient.get('/albums');
    return response.data.data;
  } catch (error) {
    console.error('Error fetching albums', error);
    throw error;
  }
};

const getAlbum = async (id) => {
  try {
    const response = await apiClient.get(`/albums/${id}`);
    return response.data.data; // Ensure it matches the response structure
  } catch (error) {
    console.error('Error fetching album', error);
    throw error;
  }
};

const updateAlbum = async (id, album, password) => {
  try {
    const response = await apiClient.put(`/albums/${id}`, { ...album, password });
    return response.data.data;
  } catch (error) {
    if (error.response) {
      console.error('Error updating album:', error.response.data);
      throw new Error(error.response.data.error || error.response.statusText);
    } else {
      console.error('Error updating album:', error.message);
      throw new Error('Error updating album');
    }
  }
};

const fetchChangelogs = async () => {
  try {
    const response = await apiClient.get('/changelogs');
    return response.data.data;
  } catch (error) {
    console.error('Error fetching changelogs', error);
    throw error;
  }
};

// New method to fetch Spotify album details by spotify_id
const getSpotifyAlbum = async (spotifyId) => {
  try {
    const response = await apiClient.get(`/spotify/albums/${spotifyId}`);
    return response.data; // Adjust as necessary to match the response structure
  } catch (error) {
    console.error('Error fetching Spotify album data:', error);
    throw new Error('Failed to fetch Spotify album data');
  }
};

export default {
  fetchAlbums,
  getAlbum,
  updateAlbum,
  fetchChangelogs,
  getSpotifyAlbum // Export the new method
};
